import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TokenService } from '../auth/token/token.service'; 

const API_URL = environment.ApiUrl;

@Injectable({
    providedIn: 'root'
})
export class colaboradorService {
    constructor(
        private http: HttpClient,
		private tokenService: TokenService,
    ) { }
    
    public getColaboradores(idEmpresa: any) {
		var permisos = this.tokenService.getPermisos();
        if(permisos.includes('adm') || permisos.includes('reh')){
            return this.http.get(API_URL+'catalogo/?catalogo=usuariosDatos&filtro1='+encodeURIComponent('idEmpresas='+idEmpresa),{});
        }else{
            var idUsuario: any = this.tokenService.getTokenVar('id');
            return this.http.get(API_URL+'catalogo/tree?catalogo=vwEstructuraOrganizacionalUsuario&id='+idUsuario+'&idEmpresa='+idEmpresa,{});
        }
    }  
    public getColaborador(id: any) {
        return this.http.get(API_URL+'catalogo/?catalogo=usuariosDatos&filtro1='+encodeURIComponent('id='+id),{});
    }
    public getColaboradorDatosFiscales(id: number) {
        return this.http.get(API_URL+'catalogo/?catalogo=usuariosDatosFiscales&filtro1='+encodeURIComponent('idUsuarios='+id),{});
    }
    public getColaboradorDatosBancarios(id: number) {
        return this.http.get(API_URL+'catalogo/?catalogo=usuariosDatosBancarios&filtro1='+encodeURIComponent('idUsuarios='+id),{});
    }
    public getColaboradorDocumentos(id: number) {
        return this.http.get(API_URL+'catalogo/?catalogo=usuariosDocumentos&filtro1='+encodeURIComponent('idUsuarios='+id),{});
    }
    public getEstructuraporIdUsuario(idUsuario: number) {
        return this.http.get(API_URL+'catalogo/?catalogo=vwEstructuraOrganizacionalEmpresa&filtro1='+encodeURIComponent('idOcupantePuesto='+idUsuario),{});
    }
    public getColaboradorHistorial(idUsuario: number) {
        return this.http.get(API_URL+'catalogo/?catalogo=vwHistorial&filtro1='+encodeURIComponent("nombreObjeto='colaboradores' and idRegistro="+idUsuario),{});
    }
}
