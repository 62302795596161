const datosEmpresa= {
    clave: '',
    descripcion: '',
    claveErp: '',
    idEmpresasPadre: null,
    createdAt: new Date().toISOString().slice(0,10)
}

const datosUsuario = {
    idNacionalidad: 0,
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    email: '',
    telefono: '',
    createdAt: new Date().toISOString().slice(0,10),
}

const datosFiscales = {
    razonSocial: '',
    rfc: '',
    calle: '',
    numero: '',
    numero_int: '',
    municipio: '',
    entidad: '',
    codigoPostal: '',
    createdAt: new Date().toISOString().slice(0,10),
    updatedAt: new Date().toISOString().slice(0,10),
    deletedAt: null
}

const datosBancarios = {
    id: null,
    idBancos: null,
    idMonedas: null,
    idTipoCuentas: null,
    cuenta: null,
    clabe: null,
    convenio: null,
    swift: null,
    aba: null,
    createdAt: new Date().toISOString().slice(0,10),
    updatedAt: new Date().toISOString().slice(0,10),
    deletedAt: null
}

const datosContactos = {
    id: null,
    idProveedores: null,
    idPuesto: null,
    nombreCompleto: null,
    email: null,
    telefono: null,
    extension: null,
    celular: null,
    contactoPrincipal: 0,
    password: null,
    idRoles: null,
    createdAt: new Date().toISOString().slice(0,10),
    updatedAt: new Date().toISOString().slice(0,10),
    deletedAt: null
}

const datosRol = {
    rol: '',
    descripcion: '',
    createdAt: new Date().toISOString().slice(0,10),
    updatedAt: new Date().toISOString().slice(0,10)
}

const datosPuesto = {
    claveEstructura: null,
    descripcionEstructura: null,
    clavePuesto: null,
    descripcionPuesto: null,
    activoPuesto: null,
    descripcionCentroCostos: null
}

const datosProveedor = {
  id: 0,
  idEmpresas: 0,
  descripcion: '',
  tipoProveedor: '',
  url: '',
  vln: '',
  pse: '',
  createdAt: new Date().toISOString().slice(0,10)
}

interface historial {
    fechaRegistro?: string,
    nombreObjeto: string,
    idRegistro: number,
    idUser?: number,
    evento: string,
    estatus?: string,
    comentarios?: string
}

export {
    datosEmpresa,
    datosUsuario,
    datosFiscales,
    datosBancarios,
    datosRol,
    datosPuesto,
    datosProveedor,
    datosContactos,
    historial
}
